import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import RequiredField from '../../required-field';
import axios from 'axios';
import Add from '../../../assets/add.png';
import s from './power-table.module.css';

const emptyClickedObject = {
  power: false,
  percentage: false,
  quantity: false,
  onPeak: false,
  offPeak: false
};

const PowerTable = ({
  setOnPeakWhrPrice,
  setOffPeakWhrPrice,
  siteName,
  setSiteName,
  electricalDevices,
  onAddElectricalDevice,
  onRemoveElectricalDevice,
  setElectricalDevices,
  customName,
  setCustomName,
  isCustomSelected,
  setIsCustomSelected
}) => {
  const [onPeakPriceString, setOnPeakPriceString] = useState('0.40');
  const [offPeakPriceString, setOffPeakPriceString] = useState('0.25');

  const [clickedArray, setClickedArray] = useState([{...emptyClickedObject}]);

  const [availAbleElectricalDevices, setAvailableElectricalDevices] = useState([]);
  const [powerList, setPowerList] = useState([]);

  useEffect(() => {
    async function call() {
      try {
        const { data } = await axios.get("https://demo-6jupdzakhq-uc.a.run.app/api/growlights/");

        setAvailableElectricalDevices(data.growlights_list);
      } catch (e) {
        console.error(e);
      }

      try {
        const { data } = await axios.get("https://demo-6jupdzakhq-uc.a.run.app/api/power/?picklist_id=" + 1);

        setPowerList(data.power_list);
      } catch (e) {
        console.error(e);
      }
    }

    call();
  }, []);

  function handleOnPeakPriceInput(event) {
    setOnPeakPriceString(event.target.value);
    const number = event.target.value.substr(1, 4);

    if (!number.includes('_')) {
      setOnPeakWhrPrice(Number(number));
    } else {
      setOnPeakWhrPrice(0);
    }
  }

  function handleSelectBlur(attribute, i) {
    const newClickedArray = [...clickedArray];

    const clickedObject = clickedArray[i];

    clickedObject[attribute] = true;

    setClickedArray(newClickedArray);
  }

  function handleOffPeakPriceInput(event) {
    setOffPeakPriceString(event.target.value);
    const number = event.target.value.substr(1, 4);

    if (!number.includes('_')) {
      setOffPeakWhrPrice(Number(number));
    } else {
      setOffPeakWhrPrice(0);
    }
  }

  function handlePercentageSelection(event, i) {
    const newDevices = [...electricalDevices];
    const device = newDevices[i];
    device.percentage = Number(event.target.value);

    setElectricalDevices(newDevices);
  }

  function handleQuantitySelection(event, i) {
    const newDevices = [...electricalDevices];
    const device = newDevices[i];
    device.quantity = Number(event.target.value);

    setElectricalDevices(newDevices);
  }

  function handleElectricalDeviceSelection(event, i) {
    const value = event.target.value;

    if (value.includes('picklist')) {
      onCustomSelection(false, i);
    } else {
      onCustomSelection(true, i);
    }

    const newDevices = [...electricalDevices];
    const device = newDevices[i];
    device.electricalDevice = value;

    setElectricalDevices(newDevices);
  }

  function handlePowerSelection(event, i) {
    const newDevices = [...electricalDevices];
    const device = newDevices[i];
    device.power = (event.target.value);

    setElectricalDevices(newDevices);
  }

  function handleOffPeakHours(event, i) {
    const newDevices = [...electricalDevices];
    const device = newDevices[i];
    const number = (event.target.value).substr(0, 2);

    if (!isNaN(Number(number))) {
      device.offPeakHours = Number(number);
      setElectricalDevices(newDevices);
    }
  }

  function handleOnPeakHours(event, i) {
    const newDevices = [...electricalDevices];
    const device = newDevices[i];
    const number = (event.target.value).substr(0, 2);

    if (!isNaN(Number(number))) {
      device.onPeakHours = Number(number);
      setElectricalDevices(newDevices);
    }
  }

  function onCustomInput(e, i) {
    const newName = e.target.value;

    const newCustomName = [...customName];

    newCustomName[i] = JSON.stringify({ name: newName });

    const nameObject = {
      target: {
        value: JSON.stringify({
          name: newName
        })
      }
    };

    setCustomName(newCustomName);

    handleElectricalDeviceSelection(nameObject, i);
  }

  function onCustomSelection(bool, i) {
    const newIsCustomSelected = [...isCustomSelected];

    newIsCustomSelected[i] = bool;

    setIsCustomSelected(newIsCustomSelected);
  }

  return (
    <>
      <div className={s["calculator-fields-header"]}>
        <div className={s["calculator-field"]}>
          <InputMask
            mask="$9.99/kWhr On Pe\ak"
            placeholder="On Peak $k/Whr"
            value={onPeakPriceString}
            onChange={handleOnPeakPriceInput}
          />
          <RequiredField active={!onPeakPriceString}>The On Peak $k/Whr field is required.</RequiredField>
        </div>
        <div className={s["calculator-field"]}>
          <InputMask
            mask="$9.99/kWhr Off Pe\ak"
            placeholder="Off Peak $k/Whr"
            value={offPeakPriceString}
            onChange={handleOffPeakPriceInput}
          />
          <RequiredField active={!offPeakPriceString}>The Off Peak $k/Whr field is required.</RequiredField>
        </div>
        <div className={s["calculator-field"]}>
          <InputMask
            placeholder="Site Name"
            value={siteName}
            onChange={e => setSiteName(e.target.value)}
          />
        </div>
      </div>
      {
        electricalDevices.map((device, i) => {
          return (
            <div key={i} className={s["calculator-fields"]}>
              <div className={`${s["calculator-field"]} ${s["first-row"]}`}>
                <select value={device.electricalDevice} onChange={e => handleElectricalDeviceSelection(e, i)}>
                  <option disabled value="">
                    Select Electrical Device
                  </option>
                  {
                    availAbleElectricalDevices.map((electricalDevice, j) => {
                      return <option key={`electrical-${j}`} value={JSON.stringify(electricalDevice)}>
                        {electricalDevice.name}
                      </option>;
                    })
                  }
                  <option value={customName[i]}>
                    Custom
                  </option>
                </select>
                <input
                  placeholder="Custom Name"
                  value={customName[i] ? JSON.parse(customName[i]).name : customName[i]}
                  onChange={e => onCustomInput(e, i)}
                  className={`${!isCustomSelected[i] ? s.hidden : s.customInput}`}
                />
              </div>
              <div className={s["calculator-field"]}>
                <select onBlur={() => handleSelectBlur('power', i)} value={device.power} onChange={e => handlePowerSelection(e, i)}>
                  <option disabled="disabled" value="">Select Power (W)</option>
                  {
                    powerList.map((power, j) => {
                      return <option key={`power-${j}`} value={power.value}>
                        {power.value}
                      </option>;
                    })
                  }
                </select>
                <RequiredField active={clickedArray[i].power && !device.power}>The Power field is required.</RequiredField>
              </div>
              <div className={s["calculator-field"]}>
                <select onBlur={() => handleSelectBlur('percentage', i)} value={device.percentage} placeholder="Select Percentage (%) of Use" onChange={e => handlePercentageSelection(e, i)}>
                  <option disabled="disabled" value="">Select Percentage (%) of Use</option>
                  <option value="0">
                    0% on/off cycle rate
                  </option><option value="0.1">
                    10% on/off cycle rate
                  </option><option value="0.2">
                    20% on/off cycle rate
                  </option><option value="0.3">
                    30% on/off cycle rate
                  </option><option value="0.4">
                    40% on/off cycle rate
                  </option><option value="0.5">
                    50% on/off cycle rate
                  </option><option value="0.6">
                    60% on/off cycle rate
                  </option><option value="0.7">
                    70% on/off cycle rate
                  </option><option value="0.8">
                    80% on/off cycle rate
                  </option><option value="0.9">
                    90% on/off cycle rate
                  </option><option value="1">
                    100% on/off cycle rate
                </option>
                </select>
                <RequiredField active={clickedArray[i].percentage && !device.percentage}>The Percentage of use field is required.</RequiredField>
              </div>
              <div className={`${s["remove-button"]} ${s["desktop-remove"]}`} onClick={() => {
                clickedArray.splice(i, 1);
                setClickedArray(clickedArray);
                onRemoveElectricalDevice(i);
              }} />
              <div className={s["calculator-field"]}>
                <select onBlur={() => handleSelectBlur('quantity', i)} value={device.quantity} onChange={e => handleQuantitySelection(e, i)}>
                  <option disabled="disabled" value="">Quantity</option>
                  <option value="1">
                    Quantity = 1
                  </option><option value="2">
                    Quantity = 2
                  </option><option value="3">
                    Quantity = 3
                  </option><option value="4">
                    Quantity = 4
                  </option><option value="5">
                    Quantity = 5
                  </option><option value="6">
                    Quantity = 6
                  </option><option value="7">
                    Quantity = 7
                  </option><option value="8">
                    Quantity = 8
                  </option><option value="9">
                    Quantity = 9
                  </option><option value="10">
                    Quantity = 10
                  </option><option value="11">
                    Quantity = 11
                  </option><option value="12">
                    Quantity = 12
                  </option><option value="13">
                    Quantity = 13
                  </option><option value="14">
                    Quantity = 14
                  </option><option value="15">
                    Quantity = 15
                  </option><option value="16">
                    Quantity = 16
                  </option><option value="17">
                    Quantity = 17
                  </option><option value="18">
                    Quantity = 18
                  </option><option value="19">
                    Quantity = 19
                  </option><option value="20">
                    Quantity = 20
                  </option><option value="21">
                    Quantity = 21
                  </option><option value="22">
                    Quantity = 22
                  </option><option value="23">
                    Quantity = 23
                  </option><option value="24">
                    Quantity = 24
                  </option><option value="25">
                    Quantity = 25
                  </option><option value="26">
                    Quantity = 26
                  </option><option value="27">
                    Quantity = 27
                  </option><option value="28">
                    Quantity = 28
                  </option><option value="29">
                    Quantity = 29
                  </option><option value="30">
                    Quantity = 30
                  </option>
                </select>
                <RequiredField active={clickedArray[i].quantity && !device.quantity}>The Quantity field is required.</RequiredField>
              </div>
              <div className={s["calculator-field"]}>
                <InputMask
                  mask="9? hours on pe\ak"
                  placeholder="On-Peak (Hrs/Day)"
                  value={device.onPeakHours.toString()}
                  onChange={e => handleOnPeakHours(e, i)}
                  onBlur={() => handleSelectBlur('onPeak', i)}
                  formatChars={{"9": "[0-9]", "?": "[0-9 ]"}} 
                  maskChar={' '}
                />
                <RequiredField active={clickedArray[i].onPeak && !(typeof device.onPeakHours === 'number' && device.onPeakHours >= 0)}>The On-Peak (Hrs/Day) field is required.</RequiredField>
              </div>
              <div className={s["calculator-field"]}>
                <InputMask
                  mask="9? hours off pe\ak"
                  placeholder="Off-Peak (Hrs/Day)"
                  value={device.offPeakHours.toString()}
                  onChange={e => handleOffPeakHours(e, i)}
                  onBlur={() => handleSelectBlur('offPeak', i)}
                  formatChars={{"9": "[0-9]", "?": "[0-9 ]"}} 
                  maskChar={' '}
                />
                <RequiredField active={clickedArray[i].offPeak && !(typeof device.offPeakHours === 'number' && device.offPeakHours >= 0)}>The Off-Peak (Hrs/Day) field is required.</RequiredField>
              </div>
              <div className={`${s["remove-button"]} ${s["mobile-remove"]}`} onClick={() => onRemoveElectricalDevice(i)}></div>
            </div>
          );
        })
      }
      <div className={s["add-item-btn"]} onClick={() => {
        setClickedArray(clickedArray.concat({...emptyClickedObject}));
        onAddElectricalDevice();
      }}>
        <span className={`${s["glyphicon"]} ${s["glyphicon-plus"]}`}>
          <img className={s["add-icon"]} src={Add} alt="Plus Icon" />
          <label>Add Another Electrical Item</label>
        </span>
      </div>
    </>
  );
};

export default PowerTable;
