import React from 'react';
import FirstImage from '../../assets/first-image.png';
import SecondImage from '../../assets/second-image.png';
import ThirdImage from '../../assets/third-image.png';
import s from './middle.module.css';

const Middle = () => {
  return (
    <div className={s["app-middle"]}>
      <p className={s["middle-title"]}>3 Easy Steps to Calculating Your Power Needs</p>
      <div className={s["images-block"]}>
        <div className={s["image-block"]}>
          <img src={FirstImage} alt="documents and power symbol" />
          <p>Calculate your energy use based on equipment selection</p>
        </div>
        <div className={s["image-block"]}>
          <img src={SecondImage} alt="solar panels" />
          <p>Install solar system for your sites</p>
        </div>
        <div className={s["image-block"]}>
          <img src={ThirdImage} alt="piggy bank" />
          <p>Run your operations with low cost electrical power</p>
        </div>
      </div>
    </div>
  );
};

export default Middle;
