import React from 'react';
import Header from '../header';
import Middle from '../middle';
import Footer from '../footer';
import s from './home.module.css';

const Home = () => {
  return (
    <div className={s.home}>
      <Header />
      <Middle />
      <Footer />
    </div>
  );
};

export default Home;
