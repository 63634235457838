import React from 'react';
import s from './required-field.module.css';

const RequiredField = ({
  children,
  active
}) => {
  return (active ? <span className={s['calculator-span-errors']}>{children}</span> : null);
};

export default RequiredField;
