import React from 'react';
import s from './footer.module.css';

const Footer = () => {
  return (
    <div className={s['app-footer']}>
      <div className={s["footer-top"]}>
        <p>
          <a href="mailto:steve@sunnycalsolar.com?bcc=dcampion@webworkspace.net" className={`${s.btn} ${s['btn-success']} ${s['contact-us']}`}>Contact Us</a>
        </p>
      </div>
      <div className={s["footer-bottom"]}>
        <p>
          This site is provided as an educational tool to enable users to manage electrical power requirements, and to be able to see benefits of solar power when applied to the users data entries.<br /><br />The information provided here will be used only by the provider of this website and our associates; it will not be sold or distributed to any third party. The results screen will be emailed to the user's email addresses; by providing the email address, the user gives permission for the website to transmit email to users.
        </p>
      </div>
    </div>
  );
};

export default Footer;
