import React, { useState } from 'react';
import Chart from 'react-google-charts';
import s from './full-results.module.css';

const colors = [
  '#8dc53e',
  '#ffaa00',
  '#0f355a',
  '#9c9588',
  '#ff7a00'
];

const FullResults = ({
  solarSystemSize,
  quantityModules,
  arrayArea,
  chartData,
  userData,
  setUserData,
  sendEmail,
  emailMessage,
  results,
  peakDailyDemand
}) => {
  const [multiplier1, setMultiplier1] = useState(1.5);
  const [multiplier2, setMultiplier2] = useState(2);

  function round(value, decimals) {
    if (typeof value === 'number') {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }
    return 0;
  }

  return (
    <div ref={results}>
      <div className={`${s["calculator-description"]} calulator-description no-negative-top ${s["no-negative-top"]}`}>
        <p className={`${s["title"]} title`}>Results</p>
        <p className={`${s["content"]} content`}>
          The electrical power for each electrical item is used for the number of days specified for each month below.
          Total electrical power cost for every month is calculated based on the electrical item’s power and operating
          days in the month for that item.
        </p>
        <div className={`${s["results-info"]} results-info`}>
          <div className={`${s['on-grid-info']} ${s['off-grid-info']} ${s["grid-info"]} grid-info on-grid-info off-grid-info`}>
            <p className={`${s["grid-title"]} grid-title`}>
              On Grid Solar System
            </p>
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td><p>Solar to cancel utility bill</p></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Solar System Size</td>
                  <td>{solarSystemSize} kW</td>
                </tr>
                <tr>
                  <td>Modules</td>
                  <td>{quantityModules} 315 W modules</td>
                </tr>
                <tr>
                  <td>Area of Array</td>
                  <td>{arrayArea} sq.ft.</td>
                </tr>
                <tr>
                  <td>Battery Size</td>
                  <td>-- kWhr</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={`${s["multipliers"]} multipliers`}>
            <p>Margin Multiplier</p>
            <div className={`${s["multipliers-input"]} multipliers-input`}>
              <div className={`${s["multiplier-field"]} multipliers-field`}>
                <input
                  value={multiplier1.toString()}
                  onChange={e => setMultiplier1(e.target.value)}
                  id="top-multiplier"
                />
                <span className={`${s["table-text"]} table-text`}>Solar</span>
              </div>
              <div className={`${s["multiplier-field"]} multiplier-field`}>
                <input
                  value={multiplier2.toString()}
                  onChange={e => setMultiplier2(e.target.value)}
                  id="bottom-multiplier"
                />
                <span className={`${s["table-text"]} table-text`}>Battery</span>
              </div>
            </div>
          </div>
          <div className={`${s["grid-info"]} ${s["off-grid-info"]} grid-info off-grid-info`}>
            <p className={`${s["grid-title"]} grid-title`}>Off Grid Solar System</p>
            <table>
              <thead className={`${s["no-mobile"]} no-mobile`}>
                <tr>
                  <td></td>
                  <td><p>&nbsp;</p></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Solar System Size</td>
                  <td>{round(Number(multiplier1) * solarSystemSize, 2)} kW</td>
                </tr>
                <tr>
                  <td>Modules</td>
                  <td>{Math.round(((Number(multiplier1) * solarSystemSize) * 1000 / 0.85) / 315)} 315 W modules</td>
                </tr>
                <tr>
                  <td>Area of Array</td>
                  <td>{round(Math.round(((Number(multiplier1) * solarSystemSize) * 1000 / 0.85) / 315) * 18.1, 2)} sq.ft.</td>
                </tr>
                <tr>
                  <td>Battery Size</td>
                  <td>{Math.round(solarSystemSize * 5.5) * Number(multiplier2)} kWhr</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={s["contact-info"]} id="contact-info">
            <p className={s["grid-title"]}>Want your analysis emailed?</p>
            <div className={s["contact-field"]}>
              <label htmlFor="contact_email_1">Email:</label>
              <input
                id={s["contact_email_1"]}
                value={userData.email}
                onChange={e => setUserData({ ...userData, email: e.target.value })}
              />
            </div>
            <div className={s["contact-field"]}>
              <label htmlFor="contact_zip">Zip Code:</label>
              <input
                id={s["contact_zip"]}
                value={userData.zip}
                onChange={e => setUserData({ ...userData, zip: e.target.value })}
              />
            </div>
            <div className={s["contact-field"]}>
              <label htmlFor="contact_name">Name:</label>
              <input
                id={s["contact_name"]}
                value={userData.name}
                onChange={e => setUserData({ ...userData, name: e.target.value })}
              />
            </div>
            <div className={s["contact-field"]}>
              <label htmlFor="contact_phone">Phone:</label>
              <input
                id={s["contact_phone"]}
                value={userData.phone}
                onChange={e => setUserData({ ...userData, phone: e.target.value })}
              />
            </div>
            <p id={s.emailMessage}>
              {emailMessage}
            </p>
            <div className={s.buttonWrapper}>
              <button onClick={sendEmail}>
                Send
              </button>
            </div>
          </div>
        </div>
        <div className={`${s["peak-data"]} peak-data`}>
          <p className={`${s["peak-data-text"]}`}><strong>Peak Daily Energy</strong> - {round(peakDailyDemand, 2)} kWhrs</p>
        </div>
        {
          chartData.data.length ?
            <Chart
              width={window.innerWidth < 765 ? "400px" : "600px"}
              height={window.innerWidth < 765 ? "200px" : "400px"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={[['data', 'labels']].concat(chartData.data.map((data, i) => {
                return [chartData.labels[i], data];
              }))}
              options={{
                title: 'Power Consumption by Each Electrical Item',
                titleTextStyle: {
                  color: '#434343',
                  fontSize: 20
                },
                colors,
                legend: { position: 'labeled' },
                pieHole: 0.4,
                backgroundColor: '#f0f0f0',
                pieSliceText: 'none'
              }}
              rootProps={{ 'data-testid': '3' }}
              style={{ display: 'inline-block' }}
            /> :
            null
        }
      </div>
    </div>
  );
};

export default FullResults;
