import React, { useState, useEffect, useRef } from 'react';
import PowerTable from './power-table';
import axios from 'axios';
import Results from './results';
import FullResults from './full-results';
import s from './calculator.module.css';

const baseMonths = {
  0: 31,
  1: 28,
  2: 31,
  3: 30,
  4: 31,
  5: 30,
  6: 31,
  7: 31,
  8: 30,
  9: 31,
  10: 30,
  11: 31
};

const emptyElectricalDevice = {
  percentage: "",
  quantity: "",
  electricalDevice: "",
  power: "",
  onPeakHours: '',
  offPeakHours: '',
  months: {...baseMonths}
};

const Calculator = () => {
  const [electricalDevices, setElectricalDevices] = useState([{ ...emptyElectricalDevice }]);
  const [onPeakWhrPrice, setOnPeakWhrPrice] = useState(0.40);
  const [offPeakWhrPrice, setOffPeakWhrPrice] = useState(0.25);
  const [siteName, setSiteName] = useState('');

  const [listCostItems, setListCostItems] = useState([]);
  //const [energyUseByItem, setEnergyUseByItem] = useState({});
  const [monthlyEnergyCost, setMonthlyEnergyCost] = useState({
    on_peak: [],
    off_peak: [],
    summary: []
  });
  const [monthlyEnergyUse, setMonthlyEnergyUse] = useState([]);
  const [solarSystemSize, setSolarSystemSize] = useState(0);
  const [quantityModules, setQuantityModules] = useState(0);
  const [arrayArea, setArrayArea] = useState(0);
  const [monthEnergyUse, setMonthEnergyUse] = useState(0);
  const [chartData, setChartData] = useState({
    data: []
  });
  const [userData, setUserData] = useState({
    email: '',
    zip: '',
    name: '',
    phone: ''
  });

  const [customName, setCustomName] = useState([JSON.stringify({ name: '' })]);
  const [isCustomSelected, setIsCustomSelected] = useState([false]);

  const [peakEnergyDemand, setPeakEnergyDemand] = useState(0);
  const [emailMessage, setEmailMessage] = useState('');

  const [editable, setEditable] = useState(true);
  const ref = useRef();
  const resultsRef = useRef();

  async function initiateEmailProcess() {
    setEditable(false);
  }

  async function sendEmail() {
    setEmailMessage('');

    const items = JSON.stringify(electricalDevices.map(device => {
      return {
        ...device,
        selected_item: device.electricalDevice,
        cycle: device.percentage,
        on_peak_hd: device.onPeakHours,
        off_peak_hd: device.offPeakHours,
        months: device.months
      }
    }));
    const on_peak = onPeakWhrPrice;
    const off_peak = offPeakWhrPrice;
    const contact_info = JSON.stringify({
      ...userData,
      contact_phone: userData.phone
    });

    const dataTable = `<div>${resultsRef.current.outerHTML}${ref.current.outerHTML}</div>`;

    try {
      await axios.post('https://demo-6jupdzakhq-uc.a.run.app/api/sendmail/', {
        items,
        on_peak,
        off_peak,
        contact_info,
        dataTable,
        siteName
      });
      setEmailMessage('Email sent successfully!');
    } catch (e) {
      console.error(e);
      setEmailMessage('Error: the message could not be sent.');
    }
    setEditable(true);
  }

  useEffect(() => {
    if (editable === false) {
      sendEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  useEffect(() => {
    let valid = electricalDevices.reduce((acc, curr) => {
      return Boolean((
        curr.percentage &&
        curr.power &&
        (
          typeof curr.onPeakHours === 'number' &&
          curr.onPeakHours >= 0
        ) &&
        (
          typeof curr.offPeakHours === 'number' &&
          curr.offPeakHours >= 0
        ) &&
        curr.quantity &&
        curr.electricalDevice
      )) && acc;
    }, true);

    //valid &= Boolean(onPeakWhrPrice && offPeakWhrPrice);

    if (valid) {
      calculate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electricalDevices, onPeakWhrPrice, offPeakWhrPrice]);

  function calculate() {
    let items = electricalDevices.map(({
      percentage,
      quantity,
      electricalDevice,
      power,
      onPeakHours,
      offPeakHours,
      months
    }) => {
      return {
        selected_item: electricalDevice,
        power,
        quantity,
        on_peak_hd: onPeakHours,
        off_peak_hd: offPeakHours,
        cycle: percentage,
        months
      }
    });

    let on_peak_whr = onPeakWhrPrice.toString();
    let off_peak_whr = offPeakWhrPrice.toString();

    let list_cost_items = [];
    let month_energy_use = 0;
    let energy_use_by_each_month = [];
    let monthly_energy_cost = {
      on_peak: [],
      off_peak: [],
      summary: [],
    };
    let energy_use_by_each_item = {
      data: [],
      labels: []
    };

    for (let k = 0; k < 12; k++) {
      energy_use_by_each_month[k] = 0;
      monthly_energy_cost["on_peak"][k] = 0;
      monthly_energy_cost["off_peak"][k] = 0;
      monthly_energy_cost["summary"][k] = 0;

      for (let i = 0; i < items.length; i++) {
        monthly_energy_cost["on_peak"][k] += Math.round(on_peak_whr.replace(",", ".") * items[i].months[k] * parseFloat(items[i].on_peak_hd) * parseInt(items[i].quantity) * parseFloat(items[i].cycle) * parseFloat(items[i].power) / 1000);
        monthly_energy_cost["off_peak"][k] += Math.round(off_peak_whr.replace(",", ".") * items[i].months[k] * parseFloat(items[i].off_peak_hd) * parseInt(items[i].quantity) * parseFloat(items[i].cycle) * parseFloat(items[i].power) / 1000);
        monthly_energy_cost["summary"][k] = monthly_energy_cost["on_peak"][k] + monthly_energy_cost["off_peak"][k];

        energy_use_by_each_month[k] += items[i].months[k] * (parseFloat(items[i].on_peak_hd) + parseFloat(items[i].off_peak_hd)) * parseInt(items[i].quantity) * parseFloat(items[i].cycle) * parseFloat(items[i].power) / 1000;
      }
    }

    let peakEnergyAux = 0;

    for (let i = 0; i < items.length; i++) {
      peakEnergyAux += (parseFloat(items[i].on_peak_hd) + parseFloat(items[i].off_peak_hd)) * parseFloat(items[i].power) * parseFloat(items[i].cycle) * parseFloat(items[i].quantity) / 1000;
      month_energy_use += items[i].months[0] * (parseFloat(items[i].on_peak_hd) + parseFloat(items[i].off_peak_hd)) * parseInt(items[i].quantity) * parseFloat(items[i].cycle) * parseFloat(items[i].power) / 1000;
    }

    for (let i = 0; i < items.length; i++) {
      let item_usage = Math.round(100 * (items[i].months[0] * (parseFloat(items[i].on_peak_hd) + parseFloat(items[i].off_peak_hd)) * parseInt(items[i].quantity) * parseFloat(items[i].cycle) * parseFloat(items[i].power) / 1000) / month_energy_use);
      let item_label = JSON.parse(items[i].selected_item);
      list_cost_items.push({
        label: item_label.name,
        months: items[i].months
      });

      energy_use_by_each_item["data"].push(item_usage);
      energy_use_by_each_item["labels"].push(item_label.name);
    }

    let solar_system_size = round(peakEnergyAux / 5.5, 2);
    let modules_qty = Math.ceil((solar_system_size * 1000 / 0.85) / 315);
    let area_of_array = round(modules_qty * 18.1, 2);
    let used_energy = energy_use_by_each_item;
    setChartData(used_energy);

    setListCostItems(list_cost_items);
    setPeakEnergyDemand(peakEnergyAux);
    //setEnergyUseByItem(energy_use_by_each_item);
    setMonthlyEnergyCost(monthly_energy_cost);
    setMonthlyEnergyUse(energy_use_by_each_month);
    setSolarSystemSize(solar_system_size);
    setQuantityModules(modules_qty);
    setArrayArea(area_of_array);
    setMonthEnergyUse(month_energy_use);
  }

  function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }

  return (
    <>
      <div className={s["calculator-description"]}>
        <p className={s["title"]}>
          Electrical Power Table Calculator
        </p>
        <p className={s["content"]}>
          Want to figure out how much power you need? Just fill out the form below.
        </p>
      </div>
      <PowerTable
        setOnPeakWhrPrice={setOnPeakWhrPrice}
        setOffPeakWhrPrice={setOffPeakWhrPrice}
        siteName={siteName}
        setSiteName={setSiteName}
        electricalDevices={electricalDevices}
        onAddElectricalDevice={() => {
          setElectricalDevices(electricalDevices.concat({ ...emptyElectricalDevice }));

          setIsCustomSelected(isCustomSelected.concat(false));
          setCustomName(customName.concat(JSON.stringify({ name: '' })));
        }}
        onRemoveElectricalDevice={i => {
          const newElectricalDevices = [...electricalDevices];

          newElectricalDevices.splice(i, 1);

          setElectricalDevices(newElectricalDevices);

          const newIsCustomSelected = isCustomSelected.splice(i, 1);
          
          setIsCustomSelected(newIsCustomSelected);

          const newCustomName = customName.splice(i, 1);

          setCustomName(newCustomName);
        }}
        setElectricalDevices={setElectricalDevices}
        customName={customName}
        setCustomName={setCustomName}
        isCustomSelected={isCustomSelected}
        setIsCustomSelected={setIsCustomSelected}
      />
      <FullResults
        solarSystemSize={solarSystemSize}
        quantityModules={quantityModules}
        arrayArea={arrayArea}
        monthEnergyUse={monthEnergyUse}
        chartData={chartData}
        userData={userData}
        setUserData={setUserData}
        sendEmail={initiateEmailProcess}
        emailMessage={emailMessage}
        results={resultsRef}
        peakDailyDemand={peakEnergyDemand}
      />
      <Results
        items={listCostItems}
        energyUseByItem={monthlyEnergyUse}
        monthlyEnergyCost={monthlyEnergyCost}
        editable={editable}
        table={ref}
        electricalDevices={electricalDevices}
        setElectricalDevices={setElectricalDevices}
      />
    </>
  );
};

export default Calculator;
