import React from 'react';
import s from './energy-table.module.css';
import './energy-table.css';

const EnergyTable = ({
  items,
  editable = true,
  energyUseByItem,
  monthlyEnergyCost,
  table,
  electricalDevices,
  setElectricalDevices
}) => {
  function handleMonthEdit(event, monthNumber, itemNumber) {
    const newMonths = JSON.parse(JSON.stringify(electricalDevices[itemNumber].months));

    newMonths[monthNumber] = Number(event.target.value);

    const newElectricalDevices = JSON.parse(JSON.stringify(electricalDevices));

    newElectricalDevices[itemNumber].months = newMonths;
    setElectricalDevices(newElectricalDevices);
  }

  return (
    <div ref={table} className={`${s["energy-table"]} energy-table`}>
      <table className={`${s["energy-cost-result"]} energy-cost-result`}>
        <tbody>
          <tr>
            <th>&nbsp;</th>
            <th>Jan</th>
            <th>Feb</th>
            <th>Mar</th>
            <th>Apr</th>
            <th>May</th>
            <th>Jun</th>
            <th>Jul</th>
            <th>Aug</th>
            <th>Sep</th>
            <th>Oct</th>
            <th>Nov</th>
            <th>Dec</th>
          </tr>
          <tr>
            <td className={`${s["el-items"]} el-items`}>Electrical Items:</td>
            {
              Array(12).fill(0).map((_, i) => {
                return <td className={`${s["el-items"]} el-items`} key={`el-items-${i}`}>&nbsp;</td>
              })
            }
          </tr>
          {
            items.map((item, i) => {
              return (
                <tr key={`items-${i}`}>
                  <td className={`${s["el-items"]} el-items`}>{item.label}</td>
                  {
                    (electricalDevices.length && electricalDevices[i] && electricalDevices[i].months) ? 
                    Object.keys(electricalDevices[i].months).map((key, j) => {
                      const month = electricalDevices[i].months[key];
                      return (
                        <td className={`${s["el-items"]} el-items`} key={`months-${j}`}>
                          {editable ?
                            <input
                              value={month.toString()}
                              onChange={e => handleMonthEdit(e, j, i)}
                            /> :
                            <span>{item.months[j]}</span>
                          }
                        </td>
                      );
                    }) : null
                  }
                </tr>
              );
            })
          }
          {
            energyUseByItem.length ?
              <tr>
                <td>Energy Use (kWhr)</td>
                {
                  energyUseByItem.map((energyUse, i) => {
                    return (
                      <td key={`energy-${i}`}>
                        {energyUse.toFixed(1)}
                      </td>
                    );
                  })
                }
              </tr> :
              null
          }
          {
            monthlyEnergyCost.on_peak.length ?
              <tr>
                <td>On-Peak Energy $</td>
                {
                  monthlyEnergyCost.on_peak.map((onPeakCost, i) => {
                    return <td key={`on-peak-${i}`}>{onPeakCost}</td>
                  })
                }
              </tr> :
              null
          }
          {
            monthlyEnergyCost.off_peak.length ?
              <tr>
                <td>Off-Peak Energy $</td>
                {
                  monthlyEnergyCost.off_peak.map((offPeakCost, i) => {
                    return <td key={`off-peak-${i}`}>{offPeakCost}</td>
                  })
                }
              </tr> :
              null
          }
          {
            monthlyEnergyCost.summary.length ?
              <tr>
                <td>Monthly Utility Cost</td>
                {
                  monthlyEnergyCost.summary.map((summaryCost, i) => {
                    return <td key={`summary-${i}`}>{summaryCost}</td>
                  })
                }
              </tr> :
              null
          }
        </tbody>
      </table>
    </div>
  );
};

export default EnergyTable;
