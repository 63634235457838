import React from 'react';
import Calculator from '../calculator';
import Logo from '../../assets/logo.png';
import SunnyCal from '../../assets/SunnyCal.png';
import s from './header.module.css';

const Header = () => {
  return (
    <div className={s["app-header"]}>
      <div className={s["logo-wrapper"]}>
        <div className={s['main-logo-wrapper']}>
          <img id={s["logo"]} src={Logo} alt="420 Powercalc" />
        </div>
        <a href="https://sunnycalsolar.com">
          <img id={s["sunny-logo"]} src={SunnyCal} alt="SunnyCal Logo" />
        </a>
      </div>
      <div className={s["calculator-arrow-mobile"]}>
        <p><b>Save Up To 70% </b><br />On Your Power Bill with Solar for your Solar System</p>
      </div>
      <div className={s["calculator-wrapper"]}>
        <div className={s["calculator-arrow"]}>
          <p>
            <b>Save Up To 70% </b>
            On Your Power Bill with Solar for your Solar System
          </p>
        </div>
        <Calculator />
      </div>
    </div>
  );
};

export default Header;
