import React from 'react';
import EnergyTable from './energy-table';
//import s from './results.module.css';

const Results = ({
  items,
  months,
  energyUseByItem,
  monthlyEnergyCost,
  setMonths,
  editable,
  table,
  electricalDevices,
  setElectricalDevices
}) => {
  return (
    <EnergyTable
      items={items}
      months={months}
      energyUseByItem={energyUseByItem}
      setMonths={setMonths}
      monthlyEnergyCost={monthlyEnergyCost}
      editable={editable}
      table={table}
      electricalDevices={electricalDevices}
      setElectricalDevices={setElectricalDevices}
    />
  );
};

export default Results;
